export const createCompleteAddress = ({
  streetAddress1,
  streetAddress2,
  city,
  state,
  zipCode,
  country,
}: {
  streetAddress1?: string; // Optional properties, as they may not always be provided
  streetAddress2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}): string => {
  // Start with an empty array to store the address components
  const addressComponents: string[] = [];

  // Add non-empty address components to the array
  if (streetAddress1) {
    addressComponents.push(streetAddress1);
  }

  if (streetAddress2) {
    addressComponents.push(streetAddress2);
  }

  if (city) {
    addressComponents.push(city);
  }

  if (state) {
    addressComponents.push(state);
  }

  if (zipCode) {
    addressComponents.push(zipCode);
  }

  if (country) {
    addressComponents.push(country);
  }

  // Join the address components with a comma and space to create the complete address
  const completeAddress = addressComponents.join(', ');

  return completeAddress;
};

export const joinPhoneNumbers = (
  ...phoneNumbers: (string | null | undefined)[]
): string | null => {
  // Filter out null or empty phone numbers
  const validNumbers = phoneNumbers.filter(
    (number): number is string => number !== null && number !== ''
  );

  // Check if there are any valid phone numbers provided
  if (validNumbers.length === 0) {
    return null;
  }

  // Format each valid phone number and join them with "/"
  const formattedNumbers = validNumbers.join(' / ');

  return formattedNumbers;
};

export const toPascalCaseWithSpaces = (
  inputString: string | null | undefined
): string | null => {
  // Check if inputString is not null or undefined
  if (inputString == null) {
    console.error('Input string is null or undefined.');
    return null; // or return an appropriate value based on your use case
  }

  // Split the string into words while preserving special characters
  const words: string[] = inputString.split(/\b/);

  // Capitalize the first letter of each word, excluding words after an apostrophe
  const pascalCaseString: string = words
    .map((word, index) => {
      // Check if the word is a word character (letter, digit, or underscore)
      if (/^\w+$/.test(word)) {
        // Check if the previous word ends with an apostrophe
        const isAfterApostrophe: boolean =
          index > 0 &&
          (words[index - 1].endsWith("'") || words[index - 1].endsWith('’'));
        return isAfterApostrophe
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word; // Retain the special character
      }
    })
    .join('');

  // Return the processed string with specific text removed
  return removeTextFromEnd(pascalCaseString, [
    ' | A Conversation With Gurudev Sri Sri Ravi Shankar',
    ' With Gurudev Sri Sri Ravi Shankar',
    ' – Gurudev Sri Sri Ravi Shankar',
    ' - Gurudev Sri Sri Ravi Shankar',
    ' | Gurudev Sri Sri Ravi Shankar',
    ' | Gurudev Sri Sri Ravi Shankar.',
    ' Gurudev Sri Sri Ravi Shankar.',
    ' Gurudev Sri Sri Ravi Shankar',
  ]);
};

export const removeTextFromEnd = (inputString, textsToRemove) => {
  for (const textToRemove of textsToRemove) {
    const lowerInput = inputString.toLowerCase();
    const lowerTextToRemove = textToRemove.toLowerCase();

    if (lowerInput.endsWith(lowerTextToRemove)) {
      return inputString.slice(0, -textToRemove.length);
    }
  }
  return inputString;
};
