import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { retrieveTokens } from 'amazon-cognito-passwordless-auth/storage';
// import { Auth } from './auth';

class HTTPError extends Error {
  statusCode: number;
  stack?: string | null;

  constructor(
    message: string,
    statusCode: number,
    stack: string | null = null
  ) {
    super(message);
    this.statusCode = statusCode;
    this.name = 'HTTPError';
    this.stack = stack;
  }
}

const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL
  ? process.env.NEXT_PUBLIC_SERVER_URL
  : 'http://localhost:3000';
const ORG_NAME = process.env.NEXT_PUBLIC_ORGANIZATION_NAME;

const axiosClient = Axios.create({
  baseURL: SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Extend InternalAxiosRequestConfig to include isUnauthorized
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  isUnauthorized?: boolean;
}

// Use the custom type in the interceptor
axiosClient.interceptors.request.use(async function (
  config: CustomAxiosRequestConfig
) {
  try {
    if (!config.isUnauthorized) {
      const { accessToken } = await retrieveTokens();
      const token = accessToken;
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    // No logged-in user: don't set auth header
  }
  return config;
});

function generateQueryString(params?: Record<string, any>): string {
  if (!params) return queryString.stringify({ org: ORG_NAME });
  return queryString.stringify({ ...params, org: ORG_NAME });
}

// Define a type for configuration
interface ApiConfig {
  param?: Record<string, any>;
  path: string;
  body?: Record<string, any>;
  [key: string]: any; // Allow additional properties
}

export const api = {
  async get(config: ApiConfig): Promise<any> {
    const { param, path, body, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.get(`${path}?${qs}`, rest);
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },

  async getFile(config: ApiConfig): Promise<Blob> {
    const { param, path, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.get(`${path}?${qs}`, {
        responseType: 'blob',
        ...rest,
      });
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },

  async post(config: ApiConfig): Promise<any> {
    const { body, param, path, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.post(`${path}?${qs}`, body, rest);
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },

  async postFormData(config: ApiConfig): Promise<any> {
    const { body, param, path, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.post(`${path}?${qs}`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...rest,
      });
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },

  async put(config: ApiConfig): Promise<any> {
    const { body, param, path, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.put(`${path}?${qs}`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
        ...rest,
      });
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },

  async delete(config: ApiConfig): Promise<any> {
    const { body, param, path, ...rest } = config;
    const qs = generateQueryString(param);
    try {
      const result = await axiosClient.delete(`${path}?${qs}`, rest);
      return result.data;
    } catch (error: any) {
      throw new HTTPError(
        error.response?.data?.message || error.message,
        error.response?.data?.statusCode || 500,
        error.response?.data?.stack
      );
    }
  },
};
