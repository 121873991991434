import { parse } from 'tldts';

export const IS_LOCAL = process.env.NODE_ENV === 'development';

const getParentDomain = () => {
  // Check if running in a browser
  if (typeof window === 'undefined') {
    return null; // Return null on the server side
  }

  const hostname = window.location.hostname; // e.g., "qa.members.us.artofliving.org"
  const { domain } = parse(hostname); // Extract the root domain using tldts

  // Fallback logic for cases where parsing fails or domain is undefined
  if (!domain) {
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`; // Fallback to "example.com"
    }
    return hostname; // Return hostname as-is
  }

  if (IS_LOCAL || domain === 'herokuapp.com') {
    return undefined;
  }
  return `.${domain}`;
};

export const PARENT_DOMAIN = getParentDomain();

export const CONTENT_FOLDER_IDS = {
  MEDITATE_FOLDER_ID: process.env.NEXT_PUBLIC_MEDITATE_FOLDER_ID,
  WISDOM_FOLDER_ID: process.env.NEXT_PUBLIC_WISDOM_FOLDER_ID,
};
