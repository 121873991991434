// components/Header.tsx
'use client';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useAuth } from '@contexts';
import Logo from '@images/ic-logo.svg';
import BackArrow from '@images/BackArrow.svg';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils';
import { navigationConfig, MenuItem } from '@/config/navigation';

const getInitials = (firstName?: string, lastName?: string): string => {
  if (!firstName && !lastName) return '';

  const firstInitial = firstName?.[0] || '';
  const lastInitial = lastName?.[0] || '';

  return (firstInitial + lastInitial).toUpperCase();
};

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const [headerMenu, setHeaderMenu] = useState([...navigationConfig]);

  const { isAuthenticated, user, redirectToLogin } = useAuth();
  const { userProfilePic, first_name, last_name } = user || {};

  const initials = getInitials(first_name, last_name);

  const { data: introData = [] } = useQuery({
    queryKey: ['get-started-intro-series'],
    queryFn: async () => {
      const response = await api.get({
        path: 'get-started-intro-series',
      });
      return response?.data;
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      // Reset to original menu when logged out
      setHeaderMenu([...navigationConfig]);
      return;
    }

    if (!introData?.length) {
      return;
    }

    setHeaderMenu((currentMenu) => {
      // Deep clone the current menu to avoid modifying the original MENU
      const updatedMenu = JSON.parse(JSON.stringify(currentMenu));
      const exploreMenu = updatedMenu.find(
        (menuItem) => menuItem.label === 'Explore'
      );

      if (!exploreMenu) {
        return currentMenu;
      }

      exploreMenu.children = [];

      // Check if items already exist to prevent duplicates
      const existingTitles = new Set(
        exploreMenu.children.map((item) => item.label)
      );

      const newItems = introData.filter(
        (item) => !existingTitles.has(item.title)
      );

      if (newItems.length === 0) {
        return currentMenu;
      }

      exploreMenu.children = [
        ...exploreMenu.children,
        ...newItems.map((item) => ({
          label: item.title,
          path: item.slug
            ? `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/explore/${item.slug}`
            : '#',
        })),
      ];

      return updatedMenu;
    });
  }, [introData, isAuthenticated]);

  const toggleMenu = (label: string) => {
    setOpenMenus((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const renderMenuItem = (item: MenuItem) => {
    if (item.children) {
      return (
        <li
          key={item.label}
          className={`nav-item dropdown ${openMenus[item.label] ? 'show' : ''}`}
        >
          <a
            onClick={() => toggleMenu(item.label)}
            aria-expanded={openMenus[item.label]}
            role="button"
            className="dropdown-toggle nav-link"
            href="#"
            target={item.target}
          >
            {item.label}
          </a>
          <div
            data-bs-popper="static"
            className={`dropdown-menu ${openMenus[item.label] ? 'show' : ''}`}
            aria-labelledby=""
          >
            <button
              onClick={() => toggleMenu(item.label)}
              className="back-link dropdown-toggle"
              id="navbarCoursesDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Image
                src={BackArrow}
                className="past-courses__cards__arrow back-link"
                alt="back arrow"
                width={24}
                height={24}
              />
            </button>
            <div className="dropdown-menu-col" data-title={item.label}>
              {item.children.map((child) => {
                if (child.children) {
                  return (
                    <div
                      className="dropdown-menu-inner"
                      data-title={child.label}
                      key={child.label}
                    >
                      <div className="dropdown-menu-col">
                        <h6 className="dropdown-header">{child.label}</h6>
                        {child.children.map((subChild) => (
                          <a
                            key={subChild.label}
                            data-rr-ui-dropdown-item=""
                            className={`dropdown-item ${subChild.label === 'All Courses' ? 'active' : ''} ${subChild.label === 'Help me choose' ? 'help' : ''}`}
                            href={subChild.path}
                            target={subChild.target}
                          >
                            {subChild.label}
                          </a>
                        ))}
                      </div>
                    </div>
                  );
                }
                return (
                  <a
                    key={child.label}
                    data-rr-ui-dropdown-item=""
                    className="dropdown-item"
                    href={child.path || '#'}
                    target={child.target}
                  >
                    {child.label}
                  </a>
                );
              })}
            </div>
          </div>
        </li>
      );
    }

    return (
      <li key={item.label} className="nav-item">
        <Link href={item.path || '#'} passHref legacyBehavior>
          <a data-rr-ui-event-key={item.path} className="nav-link">
            {item.label}
          </a>
        </Link>
      </li>
    );
  };

  return (
    <header className={`header header-v2 ${isOpen ? 'menu-opened' : ''}`}>
      <div className="header__container container">
        <Link href="/us-en" passHref legacyBehavior>
          <a className="logo">
            <Image
              src={Logo}
              alt="logo"
              className="logo__image"
              width={115}
              height={45}
            />
          </a>
        </Link>
        <nav className="navbar navbar-expand-lg navbar-light">
          <button
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="basic-navbar-nav"
            type="button"
            aria-label="Toggle navigation"
            className={`navbar-toggler-header navbar-toggler ${isOpen ? '' : 'collapsed'}`}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
          <div
            className={`navbar-collapse collapse ${isOpen ? 'show' : ''}`}
            id="basic-navbar-nav"
          >
            <div className="nav-logowrap">
              <Image
                src={Logo}
                alt="logo"
                className="logo__image"
                width={115}
                height={45}
              />
            </div>
            <ul className="mr-auto navbar-nav">
              {headerMenu.map(renderMenuItem)}
            </ul>
            <div className="mobile-menu-buttons">
              {!isAuthenticated && (
                <button
                  className="btn btn-outline header__button"
                  type="button"
                  onClick={redirectToLogin}
                >
                  Log In
                </button>
              )}
            </div>
          </div>
        </nav>
        <div
          className={
            isAuthenticated
              ? 'user-profile-link'
              : 'user-profile-link hide-link'
          }
        >
          <div className="UserprofileView">
            {!isAuthenticated && (
              <button
                className="btn btn-outline header__button"
                type="button"
                onClick={redirectToLogin}
              >
                Log In
              </button>
            )}

            {isAuthenticated && (
              <>
                {/*<Link
                  href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/profile`}
                  legacyBehavior
                >
                  <a
                    href="#"
                    className="tw-no-underline tw-text-black hover:tw-text-black hover:tw-no-underline"
                  >
                    <span className="username">{first_name || last_name}</span>
                  </a>
                </Link>*/}
                <Link
                  prefetch={false}
                  href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/profile`}
                  legacyBehavior
                >
                  <a className="header_profileHeaderImage" href="#">
                    <p className="initials">{initials}</p>
                    {userProfilePic && (
                      <Image
                        src={userProfilePic}
                        alt="PC"
                        className="rounded-circle"
                        width={40}
                        height={40}
                      />
                    )}
                  </a>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
