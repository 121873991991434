'use client';
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchAuthSession, signOut } from '@aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import CookieStorage from '../utils/cookieStorage';
import { api } from '../utils';
import { Passwordless } from 'amazon-cognito-passwordless-auth';
import {
  PasswordlessContextProvider,
  usePasswordless,
} from 'amazon-cognito-passwordless-auth/react';
import { storeTokens } from 'amazon-cognito-passwordless-auth/storage';
import { PARENT_DOMAIN, IS_LOCAL } from '@constants';

Passwordless.configure({
  clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOL,
  cognitoIdpEndpoint: process.env.NEXT_PUBLIC_COGNITO_REGION,
  storage: new CookieStorage({
    domain: PARENT_DOMAIN,
    secure: !IS_LOCAL,
  }),
  debug: console.log,
});

const amplifyConfig = {
  ssr: true,
  Auth: {
    Cognito: {
      region: process.env.NEXT_PUBLIC_COGNITO_REGION,
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOL,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code' as 'code' | 'link',
    },
  },
};

Amplify.configure(amplifyConfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: PARENT_DOMAIN,
    secure: !IS_LOCAL,
  })
);

interface AuthContextType {
  isAuthenticated: boolean;
  user: {
    first_name?: string;
    last_name?: string;
    email?: string;
    userProfilePic?: string;
  } | null;
  redirectToLogin: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <PasswordlessContextProvider enableLocalUserCache={true}>
      <AuthContextInner>{children}</AuthContextInner>
    </PasswordlessContextProvider>
  );
}

function AuthContextInner({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<AuthContextType['user']>(null);

  const {
    signInStatus, // overall auth status, e.g. is the user signed in or not? Use this field to show the relevant UI, e.g. render a sign-in page, if the status equals "NOT_SIGNED_IN"
    tokens, // raw (i.e. string) JWTs of the signed-in user: ID token, Access token and Refresh Token
    tokensParsed, // boolean, set to true during sign-in and sign-out (e.g. use this if you want to display a spinner)
  } = usePasswordless();

  useEffect(() => {
    if (signInStatus === 'SIGNED_IN') {
      checkAuthState();
    }
  }, [signInStatus]);

  const checkAuthState = async () => {
    try {
      const profile = await api.get({
        path: 'profile',
      });
      const userInfo = {
        ...profile,
      };
      console.log('userInfo', userInfo);
      setCurrentUser(userInfo);
      setIsAuthenticated(true);
    } catch (error) {
      setCurrentUser(null);
      setIsAuthenticated(false);
      console.log('Error fetching current user:', error);
      throw new Error(
        'Unable to load your profile details. Please refresh the page or contact support if the issue persists.'
      );
    }
  };

  const redirectToLogin = () => {
    const currentUrl = `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`;
    window.location.href = `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/signin?next=${encodeURIComponent(currentUrl)}`;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: currentUser,
        redirectToLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
