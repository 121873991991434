// components/Footer.tsx
'use client';
import { useState } from 'react';
import Image from 'next/image';
import Logo from '@images/ic-logo.svg';
import FbIcon from '@images/icons/Fb.svg';
import TwitterIcon from '@images/icons/Twitter.svg';
import InstaIcon from '@images/icons/Insta.svg';
import YoutubeIcon from '@images/icons/Youtube.svg';
export default function Footer() {
  const [isMeditationOpen, setIsMeditationOpen] = useState(false);
  const [isBreathOpen, setIsBreathOpen] = useState(false);
  const [isYogaOpen, setIsYogaOpen] = useState(false);
  const [isSleepOpen, setIsSleepOpen] = useState(false);
  const [isStressOpen, setIsStressOpen] = useState(false);
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isGurudevOpen, setIsGurudevOpen] = useState(false);
  const [isCoursesOpen, setIsCoursesOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isBlogsOpen, setIsBlogsOpen] = useState(false);
  return (
    <footer className="section-footer">
      <div className="subsection-footer-title">
        <div className="container text-center">
          <div className="footer-logo-wrap">
            <Image
              src={Logo}
              alt="logo"
              className="logo__image"
              width={200}
              height={76}
            />
          </div>
          <div className="section-title">
            FIND <strong>SOLUTIONS</strong>
          </div>
          <div className="section-subtitle">
            Check out more resources to help you lower your stress and find
            lasting calm
          </div>
        </div>
      </div>
      <div className="subsection-footer-topmenu">
        <div className="container">
          <div className="footer-topmenu">
            <div className={`topmenu-col ${isMeditationOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-meditation-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsMeditationOpen(!isMeditationOpen)}
                className="topmenu-col-title"
              >
                Meditation
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation">
                    Meditation Overview
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/benefits/benefits-of-meditation">
                    Benefits Of Meditation
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/beginners-guide/meditation-for-beginners">
                    Meditation For Beginners
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/how-to/how-to-meditate-properly">
                    How To Meditate
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/chakras/chakras-guide">
                    Chakras
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/beginners-guide/online-guided-meditation">
                    Guided Meditation
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/beginners-guide/what-is-meditation">
                    What Is Meditation?
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/health-and-wellness/yoga-nidra-experience">
                    Yoga Nidra Meditation
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/how-to/body-scan-meditation">
                    Body Scan Meditation
                  </a>
                </li>
              </ul>
            </div>
            <div className={`topmenu-col ${isBreathOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-breath-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsBreathOpen(!isBreathOpen)}
                className="topmenu-col-title"
              >
                Breathing
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-techniques">
                    Pranayama Breathwork
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/deep-breathing-exercises">
                    Deep Breathing Exercises
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-techniques">
                    Breathing Techniques
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/how-to-increase-lung-capacity">
                    How To Increase Lung Capacity
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-exercises-for-anxiety">
                    Breathing Exercises For Anxiety / Anxiety Breathing
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-patterns">
                    Breathing Patterns
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/pranayama/bhramari-pranayama">
                    Bhramari Breath
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/breathwork/pranayama/alternate-nostril-breathing">
                    Alternate Nostril Breathing
                  </a>
                </li>
              </ul>
            </div>
            <div className={`topmenu-col ${isYogaOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-yoga-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsYogaOpen(!isYogaOpen)}
                className="topmenu-col-title"
              >
                Yoga
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/poses/sun-salutation">
                    Sun Salutations
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/benefits/mudra-for-anxiety">
                    Mudras
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/poses/yoga-poses">
                    Yoga Poses
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/benefits/yoga-benefits">
                    Benefits Of Yoga
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/beginners/yoga-for-beginners">
                    Yoga For Beginners
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/poses/pigeon-pose">
                    Pigeon Pose
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/poses/lotus-positition">
                    Lotus Position
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/poses/shavasana">
                    Shavasana
                  </a>
                </li>
              </ul>
            </div>
            <div className={`topmenu-col ${isSleepOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-sleep-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsSleepOpen(!isSleepOpen)}
                className="topmenu-col-title"
              >
                Sleep
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation-for-sleep-relaxation-techniques-for-body-and-mind">
                    Meditation For Sleep
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/sleep-hygiene">
                    Sleep Hygiene
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/guided-sleep-meditation">
                    Guided Sleep Meditation
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/how-to-fall-back-asleep">
                    How To Fall Back Asleep
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/meditation-for-better-sleep">
                    Meditation For Better Sleep
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/sleep-tips">
                    Sleep Tips
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/yoga/health-and-wellness/yoga-nidra-experience">
                    Yoga Nidra
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/sleep/meditation-replace-sleep">
                    Meditation Replace Sleep
                  </a>
                </li>
              </ul>
            </div>
            <div className={`topmenu-col ${isStressOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-stress-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsStressOpen(!isStressOpen)}
                className="topmenu-col-title"
              >
                Stress
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/strategies-to-reduce-stress">
                    How To Relieve Stress
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/blog/how-to-calm-anxiety">
                    How To Calm Anxiety?
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/how-to-deal-with-loneliness">
                    I'm So Lonely
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/meditation-for-anxiety">
                    Meditation For Anxiety
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/how-to-calm-down">
                    How To Calm Down?
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/how-to-stop-overthinking">
                    How To Stop Overthinking?
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/meditation-for-stress">
                    Meditation For Stress
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/stress/relief/how-to-stop-worrying">
                    How To Stop Worrying?
                  </a>
                </li>
              </ul>
            </div>
            <div className={`topmenu-col ${isResearchOpen ? 'show' : ''}`}>
              <div className="topmenu-col-icon">
                <Image
                  src="/images/footer-menu-research-icon.webp"
                  alt="footer menu icon"
                  className="footer-menu-icon"
                  width={44}
                  height={44}
                />
              </div>
              <div
                onClick={() => setIsResearchOpen(!isResearchOpen)}
                className="topmenu-col-title"
              >
                Meditation Research
              </div>
              <ul className="topmenu-col-menu">
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/benefits/research-sudarshan-kriya">
                    SKY Research
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/blog/is-meditation-making-your-life-better-or-not-find-the-facts-now">
                    Meditation Research
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/blog">
                    Meditation For Heart Health
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/blog/how-meditation-changes-your-brain">
                    Meditation For A Healthy Brain
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/benefits/meditation-immunity">
                    Meditation For Immunity
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://www.artofliving.org/us-en/meditation/benefits/sudarshan-kriya-benefits">
                    Sudarshan Kriya (SKY Breath Meditation) Benefits
                  </a>
                </li>
                <li className="topmenu-col-menu-item">
                  <a href="https://event.us.artofliving.org/us-en/research/">
                    Yale Study
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="subsection-footer-mainmenu">
        <div className="container">
          <div className="footer-mainmenu">
            <div
              className={`footer-mainmenu-col ${isGurudevOpen ? 'show' : ''}`}
            >
              <div
                onClick={() => setIsGurudevOpen(!isGurudevOpen)}
                className="col-heading"
              >
                Gurudev
              </div>
              <ul className="footer-mainmenu-list">
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/gurudev">
                    About Gurudev
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/awards-and-honours">
                    Accolades
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/sri-sri-blog">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={`footer-mainmenu-col ${isCoursesOpen ? 'show' : ''}`}
            >
              <div
                onClick={() => setIsCoursesOpen(!isCoursesOpen)}
                className="col-heading"
              >
                Courses
              </div>
              <ul className="footer-mainmenu-list">
                <li className="footer-mainmenu-item">
                  <a href="https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&amp;utm_medium=home&amp;utm_content=menu&amp;course_id=811569">
                    Art of Living Part 1
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://event.us.artofliving.org/us-en/sahajsamadhi/?utm_source=organic&amp;utm_medium=home&amp;utm_content=menu&amp;course_id=999649">
                    Sahaj Samadhi Meditation
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://event.us.artofliving.org/us-en/premiumcourse">
                    Art of Living Premium
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://event.us.artofliving.org/us-en/artoflivingpart2/?utm_source=organic&amp;utm_medium=home&amp;utm_content=menu&amp;course_id=811570">
                    Art of Living Part 2
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/online-foundation-program?utm_source=organic&amp;utm_medium=home&amp;utm_content=menu&amp;course_id=1004431`}
                  >
                    Sri Sri Yoga Foundation Program
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/course?utm_source=organic&amp;utm_medium=home&amp;utm_content=allcourses`}
                  >
                    All Courses
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/advanced-courses`}
                  >
                    Advanced Courses
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://healingbreaths.org/?__hstc=49404278.aef4153bcae896637e9e5312e4b605be.1693887831201.1729946046713.1729961423093.34&amp;__hssc=49404278.1.1729961423093&amp;__hsfp=2312727440">
                    Healthcare Providers
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.skycampushappiness.org/">
                    College Courses
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://artoflivingretreatcenter.org/category/meditation/meditation-mindfulness/?utm_source=organic&amp;utm_medium=home&amp;utm_content=destinationretreats&amp;__hstc=49404278.aef4153bcae896637e9e5312e4b605be.1693887831201.1729946046713.1729961423093.34&amp;__hssc=49404278.1.1729961423093&amp;__hsfp=2312727440">
                    Destination Retreats
                  </a>
                </li>
              </ul>
            </div>
            <div className={`footer-mainmenu-col ${isAboutOpen ? 'show' : ''}`}>
              <div
                onClick={() => setIsAboutOpen(!isAboutOpen)}
                className="col-heading"
              >
                About Us
              </div>
              <ul className="footer-mainmenu-list">
                <li className="footer-mainmenu-item">
                  <a
                    href="https://www.artofliving.org/us-en/about-us"
                    target=""
                  >
                    Art of Living
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://www.artofliving.org/us-en/meditation/benefits/research-sudarshan-kriya"
                    target=""
                  >
                    Science
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://www.artofliving.org/us-en/service-projects-overview"
                    target=""
                  >
                    Service Projects
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/press" target="">
                    Press &amp; Media
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://event.us.artofliving.org/us-en/donations/"
                    target=""
                  >
                    Donate
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://endowment.us.artofliving.org/" target="">
                    Endowment
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artoflivingstore.us/" target="_blank">
                    Bookstore
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://event.us.artofliving.org/gift-cards/"
                    target=""
                  >
                    Gift Card
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/referral-offer?traffic_source=footer`}
                    target=""
                  >
                    Referral Offer
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://www.shareasale.com/shareasale.cfm?merchantID=103115"
                    target=""
                  >
                    Affiliates
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a
                    href="https://www.artofliving.org/us-en/contact-us"
                    target=""
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className={`footer-mainmenu-col ${isBlogsOpen ? 'show' : ''}`}>
              <div
                onClick={() => setIsBlogsOpen(!isBlogsOpen)}
                className="col-heading"
              >
                Blogs
              </div>
              <ul className="footer-mainmenu-list">
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/yoga">Yoga</a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/meditation">
                    Meditation
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/breathing-exercises">
                    Breathing exercises
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/wisdom">Wisdom</a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/spirituality">
                    Spirituality
                  </a>
                </li>
                <li className="footer-mainmenu-item">
                  <a href="https://www.artofliving.org/us-en/lifestyle">
                    Lifestyle
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="subsection-footer-bottom">
        <div className="container">
          <div className="footer-copyright">&copy; 2024 Art of Living</div>
          <div className="footer-links">
            <ul>
              <li>
                <a href="https://www.artofliving.org/us-en/sms-policy">
                  SMS Policy
                </a>
              </li>
              <li>
                <a href="https://www.artofliving.org/us-en/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://www.artofliving.org/us-en/cookie-policy">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="https://www.artofliving.org/us-en/terms-of-use">
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-social-links">
            <a href="https://www.facebook.com/ArtofLivingUSA" target="_blank">
              <Image
                src={FbIcon}
                alt="social icon"
                className="footer-social-icon"
                width={23}
                height={30}
              />
            </a>
            <a href="https://twitter.com/artoflivingusa" target="_blank">
              <Image
                src={TwitterIcon}
                alt="social icon"
                className="footer-social-icon"
                width={40}
                height={30}
              />
            </a>
            <a href="https://www.instagram.com/artoflivingusa/" target="_blank">
              <Image
                src={InstaIcon}
                alt="social icon"
                className="footer-social-icon"
                width={46}
                height={46}
              />
            </a>
            <a href="https://www.youtube.com/SriSri" target="_blank">
              <Image
                src={YoutubeIcon}
                alt="social icon"
                className="footer-social-icon"
                width={50}
                height={34}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
