import Cookies from 'js-cookie';

interface CookieStorageOptions {
  domain?: string;
  path?: string;
  expires?: number;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none' | 'Strict' | 'Lax' | 'None';
}

/** @class */
export default class CookieStorage {
  private domain: string | null;
  private path: string;
  private expires: number;
  private secure: boolean;
  private sameSite: 'strict' | 'lax' | 'none' | 'Strict' | 'Lax' | 'None';

  /**
   * Constructs a new CookieStorage object
   * @param {object} data Creation options.
   * @param {string} data.domain Cookies domain (default: domain of the page
   * 				where the cookie was created, excluding subdomains)
   * @param {string} data.path Cookies path (default: '/')
   * @param {integer} data.expires Cookie expiration (in days, default: 365)
   * @param {boolean} data.secure Cookie secure flag (default: true)
   * @param {string} data.sameSite Cookie request behavior (default: 'Lax')
   */
  constructor(data: CookieStorageOptions = {}) {
    this.domain = data.domain || null;
    this.path = data.path || '/';
    this.expires = data.expires !== undefined ? data.expires : 365;
    this.secure = data.secure !== undefined ? data.secure : true;

    if (data.sameSite) {
      const sameSiteValue = data.sameSite.toLowerCase() as
        | 'strict'
        | 'lax'
        | 'none';
      if (!['strict', 'lax', 'none'].includes(sameSiteValue)) {
        throw new Error(
          'The sameSite value of cookieStorage must be "lax", "strict" or "none".'
        );
      }
      if (sameSiteValue === 'none' && !this.secure) {
        throw new Error(
          'sameSite="None" requires the Secure attribute in modern browsers.'
        );
      }
      this.sameSite = sameSiteValue;
    } else {
      this.sameSite = 'lax';
    }
  }

  /**
   * Sets a specific item in cookie storage
   * @param {string} key - The key for the item
   * @param {string} value - The value to store
   * @returns {Promise<void>} A promise that resolves when the item is set
   */
  setItem(key: string, value: string): Promise<void> {
    return new Promise((resolve) => {
      const options = {
        path: this.path,
        expires: this.expires,
        domain: this.domain,
        secure: this.secure,
        sameSite: this.sameSite,
      };

      Cookies.set(key, value, options);
      resolve();
    });
  }

  /**
   * Retrieves a specific item from cookie storage
   * @param {string} key - The key for the item
   * @returns {Promise<string>} A promise that resolves with the value of the key, or rejects if not found
   */
  getItem(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const value = Cookies.get(key);
      if (value !== undefined) {
        resolve(value);
      } else {
        reject(new Error(`Item with key "${key}" not found`));
      }
    });
  }

  /**
   * Removes a specific item from cookie storage
   * @param {string} key - The key of the item to remove
   * @returns {Promise<void>} A promise that resolves when the item is removed
   */
  removeItem(key: string): Promise<void> {
    return new Promise((resolve) => {
      const options = {
        path: this.path,
        domain: this.domain,
        secure: this.secure,
        sameSite: this.sameSite,
      };

      Cookies.remove(key, options);
      resolve();
    });
  }

  /**
   * Clears all cookies set in the current domain and path
   * @returns {Promise<void>} A promise that resolves when all cookies are cleared
   */
  clear(): Promise<void> {
    return new Promise((resolve) => {
      const cookies = Cookies.get();
      Object.keys(cookies).forEach((key) => {
        this.removeItem(key);
      });
      resolve();
    });
  }
}
